@import "~styles/config";

.input{
  height: 50px;
  width: 100%;
  background: $light-dark-color;
  box-sizing: border-box;
  font-family: $font-family;
  font-size: 16px;
  color: #ffffff;
  border: none;
  padding: 0 16px;
  outline: none;
  appearance: none;
  border-radius: 0;
  &::placeholder{
    color: #ffffff;
    opacity: .3;
  }
}

.label{
  font-family: $font-family;
  color: #ffffff;
  flex-basis: 50%;
}

.label.date{
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  padding-left: 16px;
  background: $light-dark-color;
}

.input.date{
  align-self: flex-end;
  flex-basis: 50%;
  text-align: right;
  padding-right: 0;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
  }
}

.message{
  font-family: $font-family;
  color: #ffffff;
  font-size: 12px;
  font-weight: $font-regular;
  display: block;
  margin: 0 16px;
  margin-top: 8px;
}

.error{
  color: #FF0000;
}

.textarea{
  width: 100%;
  padding-top: 12px;
  height: 150px;
  resize: none;
}

.label.checkbox{
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  background: $light-dark-color !important;
  position: relative;
  pointer-events: none;
  line-height: 50px;
  padding-left: 16px;
}

.switch{
  padding: 0;
  input{
    pointer-events: all;
    cursor: pointer;
    width: 40px;
    height: 40px;
    align-self: center;
    &::before, &::after{
      content: "";
      display: block;
      position: absolute;
      right: 16px;
      top: 50%;
      border-radius: 50px;
      transform: translateY(-50%);
    }
    &::before{
      width: 50px;
      height: 25px;
      background: $dark-dark-color;
      transition: background .5s ease-in-out;
    }
    &::after{
      width: 30px;
      height: 30px;
      background: #ffffff;
      transform: translateX(-20px) translateY(-50%);
      transition: transform .5s cubic-bezier(.3,1,.3,1);
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
    }
    &:checked{
      &::before{
        background: $color-highlight;
      }
      &::after{
        transform: translateX(0px) translateY(-50%);
      }
    }
  }
}