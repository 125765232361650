.fullScreenVideoBackground{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.showOverlay{
  background: #000000;
  video{
    opacity: .3;
  }
}